<template>
	<div>
		<e-table :tableCols="monitoringTableConf" :dataOrigin="dataOrigin">
		</e-table>
	</div>
</template>

<script>
import {monitoringTableConf} from '@data/anchorData'
export default {
	name: 'Monitoring',
	data() {
		return {
			monitoringTableConf,
			dataOrigin: {
				data: [
				]
			}
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>